import Apiservice from '@/core/services/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;


export default class InvoiceService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}admin/invoice`;
    }

    paginate(data={},index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }

    getInvoice(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url);
    }

    generateInvoice(id, data) {
        let url = `${this.#api}/${id}/generate`
        return apiService.post(url,data);
    }

    sendInvoice(id) {
        let url = `${this.#api}/${id}/send-invoice`
        return apiService.post(url);
    }

    store(data) {
        let url = `${this.#api}`;
        return apiService.post(url, data);
    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url);
    }

    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url, data);
    }

    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }

}
