<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <!--begin: Wizard Form-->
            <div class="row">
              <div class="col-md-6 col-lg-6 col-sm-12">
                <h4>
                  <span>Finance invoice </span>
                </h4>
                <router-link :to="{name:'dashboard'}">
                  Dashboard
                </router-link>
                \ Finance invoice
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <v-card>
                  <v-card-title>
                    <span v-if="mySiteSetting"> {{ mySiteSetting.company_name || '' }} <br>
                    <small v-if="mySiteSetting">PAN No.: {{ mySiteSetting.pan_no || '' }}</small>
                    </span>
                  </v-card-title>
                  <v-card-text v-if="invoice">
                    <div class="row">
                      <div class="col-4">
                        <h5>Date</h5>
                        {{ invoice.created_at }}
                      </div>
                      <div class="col-4">
                        <h5>Invoice No.</h5>
                        {{ invoice.invoice_no }}
                      </div>
                      <div class="col-4">
                        <h5>Invoice To</h5>
                        {{ invoice.invoiced_to }} <br>
                        {{ invoice.invoice_address }}<br>
                        {{ invoice.payer_phone }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <table class="table">
                          <thead>
                          <tr>
                            <th>Item</th>
                            <th>Quantity</th>
                            <th>Total</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(item, i) of invoice.items" :key="i">
                            <td>{{ item.financial_title }}</td>
                            <td>{{ item.quantity }}</td>
                            <td class="">Rs. {{ item.item_price }}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-8"></div>
                      <div class="col-4">
                        <table class="table ">
                          <tbody>
                          <tr>
                            <th>Sub Total</th>
                            <td>Rs. {{ invoice.sub_total }}</td>
                          </tr>
                          <tr>
                            <th>Discount</th>
                            <td>Rs. {{ invoice.discount_amount }}</td>
                          </tr>
                          <tr v-if="mySiteSetting">
                            <th>Gst({{ mySiteSetting.tax_percentage }}%)</th>
                            <td>Rs. {{ invoice.tax_total || '0.00' }}</td>
                          </tr>
                          <tr>
                            <th>Total</th>
                            <td>Rs. {{ invoice.invoice_total }}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="col-12 text-right">
                <v-btn class="btn btn-primary text-white" @click="generateReport">
                  {{invoice && invoice.file ? "Regenerate Invoice PDF" : "Generate Invoice PDF"}}
                </v-btn>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <vue-html2pdf
                    :show-layout="false"
                    :float-layout="true"
                    :enable-download="false"
                    :preview-modal="true"
                    :paginate-elements-by-height="1400"
                    :filename="'invoice.pdf'"
                    :pdf-quality="2"
                    :manual-pagination="false"
                    pdf-format="a4"
                    pdf-orientation="landscape"
                    pdf-content-width="100%"
                    @progress="onProgress($event)"
                    ref="html2Pdf"
                >
                  <section slot="pdf-content">
                    <div class="row">
                      <div class="col-12">
                        <v-card>
                          <v-card-title>
                    <span v-if="mySiteSetting"> {{ mySiteSetting.company_name || '' }} <br>
                    <small v-if="mySiteSetting">PAN No.: {{ mySiteSetting.pan_no || '' }}</small>
                    </span>
                          </v-card-title>
                          <v-card-text v-if="invoice">
                            <div class="row">
                              <div class="col-4">
                                <h5>Date</h5>
                                {{ invoice.created_at }}
                              </div>
                              <div class="col-4">
                                <h5>Invoice No.</h5>
                                {{ invoice.invoice_no }}
                              </div>
                              <div class="col-4">
                                <h5>Invoice To</h5>
                                {{ invoice.invoiced_to }} <br>
                                {{ invoice.invoice_address }}<br>
                                {{ invoice.payer_phone }}
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
                                <table class="table">
                                  <thead>
                                  <tr>
                                    <th>Item</th>
                                    <th>Quantity</th>
<!--                                    <th>Price</th>-->
                                    <th>Total</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr v-for="(item, i) of invoice.items" :key="i">
                                    <td>{{ item.financial_title }}</td>
                                    <td>{{ item.quantity }}</td>
<!--                                    <td>Rs. {{ item.item_price }}</td>-->
                                    <td class="">Rs. {{ item.item_price }}</td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-8"></div>
                              <div class="col-4">
                                <table class="table ">
                                  <tbody>
                                  <tr>
                                    <th>Sub Total</th>
                                    <td>Rs. {{ invoice.sub_total }}</td>
                                  </tr>
                                  <tr>
                                    <th>Discount</th>
                                    <td>Rs. {{ invoice.discount_amount }}</td>
                                  </tr>
                                  <tr v-if="mySiteSetting">
                                    <th>Gst({{ mySiteSetting.tax_percentage }}%)</th>
                                    <td>Rs. {{ invoice.tax_total || '0.00' }}</td>
                                  </tr>
                                  <tr>
                                    <th>Total</th>
                                    <td>Rs. {{ invoice.invoice_total }}</td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </v-card-text>
                        </v-card>
                      </div>
                    </div>
                  </section>
                </vue-html2pdf>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf';
import InvoiceService from "@/services/invoice/InvoiceService";

const invoiceService = new InvoiceService();
export default {
  name: "Index",
  components: {
    VueHtml2pdf
  },
  data() {
    return {
      invoice: {},
    }
  },
  computed: {
    invoiceId() {
      return this.$route.params.id;
    }
  },
  mounted() {
    this.getInvoice()
  },
  methods: {

    getInvoice() {
      invoiceService.getInvoice(this.invoiceId).then(response => {
        this.invoice = response.data.data
      }).catch(() => {});
    },

    generateReport() {
      this.$refs['html2Pdf'].generatePdf()
    },

    onProgress() {
      let pdf_file = this.$refs['html2Pdf'].pdfFile;
      if (pdf_file) {
        let url = new URL(pdf_file);
        this.convertBlobToBase64(url);
      }
    },

    convertBlobToBase64(url) {
      let request = new XMLHttpRequest();
      request.open('GET', url, true);
      request.responseType = 'blob';
      const blobToBase64 = () => {
        return new Promise(resolve => {
          request.onload = function() {
            const reader = new FileReader();
            reader.readAsDataURL(request.response);
            reader.onloadend = () => {
              resolve(reader.result);
            };

          };
        });
      };
      request.send();

      blobToBase64().then(res => {
        this.convertBlobURLToFIle(res);
      });
    },

    convertBlobURLToFIle(base64Pdf) {
      if (base64Pdf.startsWith('data:')) {
        let arr = base64Pdf.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            str = atob(arr[arr.length - 1]),
            n = str.length,
            u8arr = new Uint8Array(n);
        while(n--){
          u8arr[n] = str.charCodeAt(n);
        }
        let file = new File([u8arr], "invoice.pdf",  {type:mime});
        this.generateInvoicePdf(file);
      }
    },

    generateInvoicePdf(myFile) {
      let formData = new FormData();
      formData.append('pdf_file', myFile);
      invoiceService.generateInvoice(this.invoiceId, formData).then(response => {
        if(response.data.status === "OK") {
          this.$snotify.success("Generate invoice successfully");
          this.getInvoice();
        } else {
          this.$snotify.error("Oops something went wrong");

        }
      }).catch(() => {});
    }
  }
}
</script>
